import axios from 'axios'
import { API_CONFIG } from '@/config/api'

const API_URL = API_CONFIG.API_URL

export const submitTestResults = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/test_results`, data)
    return response.data
  } catch (error) {
    console.error('Error submitting test results:', error)
    throw error
  }
}

import { createRouter, createWebHistory } from 'vue-router'
import AssessmentResult from '../views/AssessmentResult.vue'
import SelfCarePage from '../views/SelfCarePage.vue'
import ArticlesPage from '../views/ArticlesPage.vue'
import BlogView from '../views/BlogView.vue'
import BlogPostView from '../views/BlogPostView.vue'
import NotFound from '../views/404Page.vue'
import DayBookingView from '../views/DayBookingView.vue'
import MainMenuView from '../views/MainMenuView.vue'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: MainMenuView
    },
    {
      path: '/assessment',
      name: 'Assessment',
      component: () => import('../views/AssessmentPage.vue')
    },
    {
      path: '/assessment/9q',
      name: 'Assessment9Q',
      component: () => import('../views/Assessment9Q.vue')
    },
    {
      path: '/assessment/8q',
      name: 'Assessment8Q',
      component: () => import('../views/Assessment8Q.vue')
    },
    {
      path: '/assessment/8q/result',
      name: 'Assessment8QResult',
      component: () => import('../views/Assessment8QResult.vue')
    },
    {
      path: '/assessment/result',
      name: 'AssessmentResult',
      component: AssessmentResult,
      props: true
    },
    {
      path: '/assessment/unavailable',
      name: 'AssessmentUnavailable',
      component: () => import('../views/AssessmentUnavailable.vue')
    },
    {
      path: '/result/9q',
      name: 'Result9Q',
      component: () => import('../views/Result9Q.vue')
    },
    {
      path: '/result-9q',
      name: 'Result9Q',
      component: () => import('../views/Result9Q.vue')
    },
    {
      path: '/self-care',
      name: 'self-care',
      component: SelfCarePage
    },
    {
      path: '/articles',
      name: 'articles',
      component: ArticlesPage
    },
    {
      path: '/chatbot',
      name: 'Chatbot',
      component: () => import('../views/ChatbotPage.vue')
    },
    {
      path: '/contact',
      name: 'Contact',
      component: () => import('../views/ContactPage.vue')
    },
    {
      path: '/booking',
      name: 'BookingForm',
      component: () => import('../views/BookingForm.vue')
    },
    {
      path: '/booking-name',
      name: 'BookingFormWithName',
      component: () => import('../views/BookingFormWithName.vue')
    },
    {
      path: '/day-booking',
      name: 'DayBooking',
      component: DayBookingView
    },
    {
      path: '/blog',
      name: 'Blog',
      component: BlogView
    },
    {
      path: '/blog/:id',
      name: 'BlogPost',
      component: BlogPostView,
      props: true
    },
 
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      component: NotFound
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

export default router
